import React, { type ReactNode, useContext, useEffect, useMemo } from "react";
import has from "lodash/has";
import {
  type StatusAndOddsOutcome,
  useBetslip,
  useDispatch,
  useIsLoggedIn,
  usePermissions,
  usePreviousValue,
  useSelector,
  useUserEventFilters,
} from "hooks";
import type { Outcome } from "hooks/firestore/useFirestoreOutcomeStatusAndOdds";
import { useMultipleOutcomeStatusAndOdds } from "hooks/firestore/useFirestoreOutcomeStatusAndOdds";
import { FirebaseContext } from "context/Firebase";
import {
  ACCEPT_ODDS_AUTO,
  ACCEPT_ODDS_AUTO_HIGHER,
} from "hooks/firestore/user/usePreferences";
import {
  changeOdds,
  clearRejected,
  selectCanGetOddsUpdates,
  selectHasRelated,
  setPendingOddsChange,
  statusChangeOnSelection,
  updateSelection,
} from "sections/Betting/Betslip/betslipSlice";
import type { BetSelectionType } from "types/BetTypes";
import useHasPermission from "hooks/useHasPermission";
import { requestSameRaceMultiOdds } from "utilities/api";
import type { alertVariants } from "components/Alert/Alert";
import type { VariantProps } from "class-variance-authority";
import { navigate } from "library";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { ReactComponent as AcceptIcon } from "sections/Betting/Betslip/assets/accepted.svg";
import type { StatusEnum } from "components/layout/EventStatus";
import { ShareEntryAction } from "components/layout/Entry/components/share/ShareEntryAction";
import { useShareEntryModal } from "components/layout/Entry/components/share/useShareEntryModal";
import { useEntry } from "hooks/firestore/betting/useEntries";
import type { BetEntry } from "sections/Entries/types";
import { ReactComponent as ShareIcon } from "components/assets/share.svg";
import * as styles from "../components/SingleCardAlert.module.scss";

type Selection = {
  status: OutcomeStatus;
  isActive: boolean;
  loading: boolean;
  eventStatus: StatusEnum;
  streamUri?: string;
} & BetSelectionType;

type OutcomeStatus =
  | "ACTIVE"
  | "UNAVAILABLE"
  | "SUSPENDED"
  | "DEACTIVATED"
  | "SETTLED"
  | "CANCELLED"
  | "LIVE"
  | "WAITING";

type SelectionAlert = {
  content: ReactNode;
  icon?: ReactNode;
  cta?: {
    label?: string | ReactNode;
    action?: () => void;
    animateIn?: boolean;
  };
} & Pick<VariantProps<typeof alertVariants>, "variant">;

const getOutcomesBySelection = (selection: BetSelectionType): Outcome[] => {
  const isMultiSelection = !!selection.subOutcomes;

  return isMultiSelection
    ? (selection?.subOutcomes || []).map((subOutcome) => ({
        marketId: subOutcome.marketId,
        outcomeId: subOutcome.outcomeId,
      }))
    : [{ marketId: selection.marketId, outcomeId: selection.outcomeId }];
};

const getOutcomeStatus = (
  outcome: StatusAndOddsOutcome,
  sourceType: string,
): OutcomeStatus => {
  // If the firebase subscription is still loading we make active
  if (typeof outcome === "undefined") return "ACTIVE";

  if (outcome?.result !== "UNDECIDED") return "UNAVAILABLE";

  if (!outcome?.active) return "UNAVAILABLE";
  if (sourceType !== "race" && outcome?.odds === 1) return "UNAVAILABLE";

  return (
    (outcome?.marketStatus as OutcomeStatus) ??
    (outcome?.eventStatus as OutcomeStatus) ??
    "UNAVAILABLE"
  );
};

const useSelection = (outcomeId: string): Selection => {
  const selection = useSelector((state) => state.betslip.selections[outcomeId]);
  const canViewLiveBets = useHasPermission("viewLiveMarkets");

  const outcomesToListenTo = useMemo(
    () => getOutcomesBySelection(selection),
    [selection],
  );

  const [liveOddsAndStatus, loading] = useMultipleOutcomeStatusAndOdds(
    selection.eventId,
    selection.sourceType,
    outcomesToListenTo,
  );

  // if we have a specific outcomeId we use that, otherwise we just use the first one
  const outcome =
    liveOddsAndStatus[outcomeId] || Object.values(liveOddsAndStatus)[0];

  const status = getOutcomeStatus(outcome, selection.sourceType);

  const eventStatus = outcome?.eventStatus as StatusEnum;

  return useMemo(() => {
    return {
      ...selection,
      eventStatus,
      status,
      loading,
      streamUri: outcome?.streamUri,
      isActive:
        status === "ACTIVE" ||
        (["WAITING", "LIVE"].includes(status) && canViewLiveBets),
    };
  }, [
    selection,
    status,
    eventStatus,
    loading,
    canViewLiveBets,
    liveOddsAndStatus,
    outcome,
  ]);
};

const useUpdateSelectionOddsAndStatus = (outcomeId: string) => {
  const selection = useSelector((state) => state.betslip.selections[outcomeId]);
  const isMultiSelection = !!selection.subOutcomes;
  const dispatch = useDispatch();
  const { userPreferences } = useContext(FirebaseContext);
  const acceptOdds = userPreferences?.acceptOdds;
  const token = useSelector((state) => state?.auth?.token);
  const canGetOddsUpdates = useSelector(selectCanGetOddsUpdates);

  const outcomesToListenTo = useMemo(
    () => getOutcomesBySelection(selection),
    [selection],
  );

  const [liveOddsAndStatus, loading] = useMultipleOutcomeStatusAndOdds(
    selection.eventId,
    selection.sourceType,
    outcomesToListenTo,
  );
  const previousLiveOddsAndStatus = usePreviousValue(liveOddsAndStatus);

  const updateOdds = (newOdds: number) => {
    if (acceptOdds === ACCEPT_ODDS_AUTO) {
      dispatch(changeOdds({ outcomeId, odds: newOdds }));
    } else if (
      acceptOdds === ACCEPT_ODDS_AUTO_HIGHER &&
      newOdds > selection.odds
    ) {
      dispatch(changeOdds({ outcomeId, odds: newOdds }));
    } else {
      dispatch(setPendingOddsChange({ outcomeId, newOdds }));
    }
  };

  const updateStatus = (newStatus: OutcomeStatus) => {
    dispatch(statusChangeOnSelection({ outcomeId, marketStatus: newStatus }));
  };

  // update selection status
  useEffect(() => {
    if (!canGetOddsUpdates || loading) return;

    const liveOutcome = liveOddsAndStatus[outcomeId];
    const status = getOutcomeStatus(liveOutcome, selection.sourceType);
    const statusChanged = status !== selection.changes.status;

    // for some reason firestore returns 1 frame of data without some fields, need to populate all of them before we continute
    const partiallyLoaded = !has(liveOutcome || {}, "active");
    if (!statusChanged || !liveOutcome || partiallyLoaded) return;

    updateStatus(status);
  }, [liveOddsAndStatus, canGetOddsUpdates]);

  // update single selection odds
  // process is slightly different for multi selection so we quit early for multi selection
  useEffect(() => {
    if (!canGetOddsUpdates) return;
    if (isMultiSelection || loading) return;

    // this is live data for the single outcome on a single selection market
    const liveOutcome = liveOddsAndStatus[outcomeId];
    const partiallyLoaded = !has(liveOutcome || {}, "active");
    const oddsChanged = liveOutcome?.odds !== selection.odds;
    if (!oddsChanged || partiallyLoaded) return;

    updateOdds(liveOutcome?.odds);
  }, [liveOddsAndStatus, isMultiSelection, canGetOddsUpdates]);

  // update multi selection odds
  useEffect(() => {
    if (!canGetOddsUpdates) return;
    if (!isMultiSelection || loading) return;

    // sometimes we get new live status even when odds has not changed
    // if that happens we check manually and then quit early
    const hasChanges = Object.keys(liveOddsAndStatus).some((key) => {
      const liveOdds = liveOddsAndStatus[key]?.odds;
      const previousOdds = previousLiveOddsAndStatus?.[key]?.odds;

      if (previousOdds === null) return false;

      return liveOdds !== previousOdds;
    });

    if (!hasChanges) return;

    const handleFetchingAndUpdatingOdds = async () => {
      try {
        const response = await fetchMultiSelectionOdds(
          (selection?.subOutcomes || []).map(
            (subOutcome) => subOutcome.outcomeId,
          ),
          token,
        );

        const newOdds = response?.bets?.one?.odds;

        if (newOdds && Number(selection.odds) !== newOdds) {
          updateOdds(newOdds);
        }
      } catch (e) {
        console.error(e);
      }
    };

    handleFetchingAndUpdatingOdds();
  }, [liveOddsAndStatus, token, canGetOddsUpdates]);
};

const useSelectionAlert = (selection: Selection): SelectionAlert => {
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();
  const isRelated = useSelector(selectHasRelated);
  const { t } = useTranslation();
  const {
    props: { betType },
  } = useBetslip();
  const isMulti = betType === "MULTI";
  const { submitRacingBet, submitEsportsBet, submitSportsBet, submitBet } =
    usePermissions();
  const userFilters = useUserEventFilters();

  const [setShareEntryModalOpen, getModalProps] = useShareEntryModal();
  const [entryToShare, isLoadingEntry] = useEntry<BetEntry>(selection.betId);

  // if user has event filters we need to make sure selection filters matches every single one of them
  const fitsFilters =
    userFilters.length > 0
      ? userFilters.every((filter) => selection.filters.includes(filter))
      : true;

  if (
    isLoggedIn &&
    ((selection.hub === "racing" && submitRacingBet !== "GRANTED") ||
      (selection.hub === "esports" && submitEsportsBet !== "GRANTED") ||
      (selection.hub === "sports" && submitSportsBet !== "GRANTED") ||
      submitBet !== "GRANTED" ||
      !fitsFilters)
  ) {
    return {
      variant: "warning",
      content: `You are not permitted to bet on this event`,
    };
  }

  if (isRelated(selection.outcomeId) && isMulti) {
    return {
      variant: "warning",
      content: `${t("Multi")} not available for these selections`,
    };
  }

  if (selection.isIntercepted) {
    return {
      variant: "warning",
      content: "Bet under review. Please stand by",
    };
  }

  if (selection.rejected) {
    let label = "Got It";
    let action;

    if (selection.rejected.reason === "insufficient.funds") {
      label = "Deposit";
      action = () => {
        dispatch(clearRejected(selection.outcomeId));
        navigate(`/wallet/deposit`);
      };
    } else if (selection.rejected.alternativeStake) {
      label = "Update";
      action = () => {
        const newStake = new BigNumber(selection.rejected.alternativeStake)
          .dividedBy(100)
          .toNumber();
        dispatch(
          updateSelection({
            outcomeId: selection.outcomeId,
            stake: newStake,
            rejected: null,
          }),
        );
      };
    } else {
      action = () => dispatch(clearRejected(selection.outcomeId));
    }

    return {
      variant: "danger",
      content: selection.rejected.message,
      cta: {
        label,
        action,
      },
    };
  }

  if (selection.betId && selection.entryId) {
    const hasEntryToShare = !!entryToShare;

    return {
      variant: "info",
      content: (
        <>
          {`Bet Accepted: B-${selection.entryId}`}
          {hasEntryToShare && (
            <ShareEntryAction
              {...getModalProps({
                entry: [entryToShare, isLoadingEntry],
                referenceId: selection.betId,
              })}
            />
          )}
        </>
      ),
      ...(hasEntryToShare && {
        cta: {
          label: (
            <>
              <ShareIcon className={styles.clampIcon} />
              Share
            </>
          ),
          action: () => setShareEntryModalOpen(true),
          animateIn: true,
        },
      }),
      icon: <AcceptIcon />,
    };
  }

  if (!selection.isActive) {
    return {
      variant: "warning",
      content: "A Market related to this selection is not currently active",
    };
  }

  if (!!selection.changes?.newOdds && !selection.loading) {
    return {
      variant: "danger",
      content: "Odds Updated",
      cta: {
        label: "Accept",
        action: () =>
          dispatch(
            changeOdds({
              outcomeId: selection.outcomeId,
              odds: selection.changes.newOdds,
            }),
          ),
      },
    };
  }

  return null;
};

const fetchMultiSelectionOdds = (outcomeIds: string[], token: string) => {
  return requestSameRaceMultiOdds({
    token: token,
    payload: {
      bets: {
        one: {
          selections: [{ outcomeIds }],
        },
      },
    },
  });
};

export { useSelection, useSelectionAlert, useUpdateSelectionOddsAndStatus };
