import React from "react";
import { useSelector, useDispatch, useBetslip } from "hooks";
import { useBoolean } from "usehooks-ts";
import cx from "classnames";

import {
  selectPossibleCombinations,
  selectStakePerCombination,
  setCombinationTotalStake,
  setCombinationPerComboStake,
  syncCombinationsTotalStake,
  selectSelectionsWithoutUnused,
  setIsCombinationUsingPromo,
} from "sections/Betting/Betslip/betslipSlice";
import { Card } from "./Card";
import { CardFooter } from "./CardFooter";
import { Stake } from "./Stake";
import { ComboCardAlert } from "./ComboCardAlert";
import { SupportLink } from "components/SupportLink";
import { Caret } from "components/Caret";
import { Drawer } from "components/Drawer/Drawer";
import { AnimatePresence, motion } from "framer-motion";
import { Checkbox } from "components/Checkbox";
import { useRemainingPromoBalance } from "sections/Betting/Betslip/hooks/useRemainingPromoBalance";
import { BonusBadge } from "./BonusBadge";
import BigNumber from "bignumber.js";
import { PermissionsGate } from "components/layout/PermissionsGate";

import { ReactComponent as BetCombo } from "components/assets/bet-combo.svg";
import { ReactComponent as InfoIcon } from "components/assets/info.svg";
import { ReactComponent as ComboIcon } from "sections/Betting/Betslip/assets/combo.svg";

import * as styles from "./Combinations.module.scss";

// this method is rounding down to 2 decimal places to make sure we are never producing values like 1.0000000000000001
// which can cause issues when calculating stake that produces values larger than the balance
const safeStakeToCents = (amount: number) =>
  BigNumber(amount).multipliedBy(100).dp(2, BigNumber.ROUND_FLOOR).toNumber();

const getTotalStakeInCents = (stakePerCombo: number, combinations: number) =>
  safeStakeToCents(
    BigNumber(stakePerCombo).multipliedBy(combinations).toNumber(),
  );

const Combinations = () => {
  const dispatch = useDispatch();
  const combinations = useSelector(selectPossibleCombinations);
  const combinationsStake = useSelector(selectStakePerCombination);
  const remainingPromoBalance = useRemainingPromoBalance();
  const { value: isExpanded, toggle: toggleExpanded } = useBoolean(true);
  const {
    props: {
      betslipIsConfirming,
      betslipIsReviewingMessages,
      betslipIsSubmitting,
      betslipIsDuplicate,
    },
  } = useBetslip();

  const maybeDisablePromo = (
    currentStakeInCents: number,
    newStakeInCents: number,
    type: string,
  ) => {
    const remainingPromoBalanceWithoutStake =
      remainingPromoBalance + currentStakeInCents;

    // if the remaining promo balance without current stake is less than a new stake
    // we need to disable promo toggle
    if (remainingPromoBalanceWithoutStake < newStakeInCents) {
      dispatch(
        setIsCombinationUsingPromo({
          type,
          active: false,
        }),
      );
    }
  };

  const updateTotalStake = (
    type: string,
    combinations: number,
    currentStake: number,
    newStake: number,
  ) => {
    maybeDisablePromo(
      safeStakeToCents(currentStake),
      safeStakeToCents(newStake),
      type,
    );

    dispatch(setCombinationTotalStake({ type, stake: newStake, combinations }));
  };

  const updatePerComboStake = (
    type: string,
    combinations: number,
    currentStakePerCombo: number,
    newStakePerCombo: number,
  ) => {
    const totalNewStake = getTotalStakeInCents(newStakePerCombo, combinations);
    const totalCurrentStake = getTotalStakeInCents(
      currentStakePerCombo,
      combinations,
    );

    maybeDisablePromo(totalCurrentStake, totalNewStake, type);

    dispatch(
      setCombinationPerComboStake({
        type,
        stake: newStakePerCombo,
        combinations,
      }),
    );
  };

  return (
    <>
      {!betslipIsConfirming &&
      !betslipIsReviewingMessages &&
      !betslipIsSubmitting ? (
        <div
          className={cx(styles.sectionTitle, {
            [styles.isExpanded]: isExpanded,
          })}
          onClick={toggleExpanded}
        >
          <ComboIcon className={styles.comboIcon} />
          Combination Multis
          <SupportLink
            onClick={(e) => e.stopPropagation()}
            options={{ articleId: "10425926564623" }}
            hideChevron
          >
            <InfoIcon className={styles.infoIcon} />
          </SupportLink>
          <span className={styles.caret}>
            <Caret variant={isExpanded ? "up" : "down"} />
          </span>
        </div>
      ) : null}

      {combinations.map((combination) => {
        const totalStake = combinationsStake[combination.type]?.total || 0;
        const perComboStake = combinationsStake[combination.type]?.combo || 0;
        const isPromo = combinationsStake[combination.type]?.isPromo || false;

        // When it's just a normal multi, call it ALL
        const isAll = combination.count === 1;

        if (
          (betslipIsDuplicate ||
            betslipIsConfirming ||
            betslipIsReviewingMessages ||
            betslipIsSubmitting) &&
          totalStake === 0
        ) {
          return null;
        }

        // we hide promos empty promos during submitting and review
        const shouldHidePromo =
          betslipIsReviewingMessages || betslipIsSubmitting;

        const shouldShowPromo =
          remainingPromoBalance !== 0 &&
          remainingPromoBalance / 100 >= totalStake &&
          !shouldHidePromo;

        const remainingPromoBalancePerCombo = BigNumber(remainingPromoBalance)
          .div(combination.count)
          .dp(0, BigNumber.ROUND_DOWN)
          .toNumber();

        return (
          <Drawer
            className={styles.drawer}
            isOpen={isExpanded}
            key={`combination-${combination.type}`}
          >
            <ComboCardAlert type={combination.type} />
            <Card
              className={styles.card}
              key={`combination-${combination.type}`}
            >
              <div className={styles.description}>
                <div className={styles.icon}>
                  <BetCombo />
                </div>
                <div className={styles.title}>
                  <div className={styles.type}>
                    {isAll
                      ? `All ${combination.type} Win`
                      : `Any ${combination.type} Win`}
                  </div>
                  <div className={styles.count}>
                    {combination.count}{" "}
                    {combination.count > 1 ? `Combinations` : `Combination`}
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <CardFooter.Container>
                  <CardFooter.Section label="Total Stake">
                    <Stake
                      isPromo={isPromo}
                      amount={totalStake}
                      onChange={(newStake) =>
                        updateTotalStake(
                          combination.type,
                          combination.count,
                          totalStake,
                          newStake,
                        )
                      }
                      onBlur={() =>
                        dispatch(
                          syncCombinationsTotalStake({
                            type: combination.type,
                            combinations: combination.count,
                          }),
                        )
                      }
                      isActive={!betslipIsSubmitting}
                    />
                    {isPromo && <BonusBadge />}
                  </CardFooter.Section>
                  <CardFooter.Section label="Stake/Combo">
                    <Stake
                      isPromo={isPromo}
                      amount={perComboStake}
                      onChange={(newStake) =>
                        updatePerComboStake(
                          combination.type,
                          combination.count,
                          perComboStake,
                          newStake,
                        )
                      }
                      isActive={true}
                      quickInputPosition={"middle"}
                      remainingPromoBalance={remainingPromoBalancePerCombo}
                    />
                  </CardFooter.Section>
                  <CardFooter.Section label="Est. Return">
                    TBD
                  </CardFooter.Section>
                </CardFooter.Container>
                <AnimatePresence>
                  {shouldShowPromo || isPromo ? (
                    <PermissionsGate permissions={["submitPromoComboMultiBet"]}>
                      <motion.div
                        initial={{
                          opacity: 0,
                          height: 0,
                        }}
                        animate={{
                          opacity: 1,
                          height: `auto`,
                        }}
                        exit={{
                          opacity: 0,
                          height: 0,
                        }}
                        className={styles.options}
                      >
                        <Checkbox
                          className={styles.bonus}
                          noSpacing
                          label={`Bonus Cash`}
                          labelClassName={styles.label}
                          checked={isPromo}
                          disabled={betslipIsReviewingMessages}
                          onCheckedChange={(checked) => {
                            dispatch(
                              setIsCombinationUsingPromo({
                                type: combination.type,
                                active: !!checked,
                              }),
                            );
                          }}
                        />
                      </motion.div>
                    </PermissionsGate>
                  ) : null}
                </AnimatePresence>
              </div>
            </Card>
          </Drawer>
        );
      })}
    </>
  );
};

export { Combinations };
